import { PipeTransform } from '@angular/core';

export class Globle
{
    name :any;
    static urlArray;
    static id=0
    static assetId
    static customerId
    static invoicedata 
    static invoiceVoucherNo
   static movementId
   static maintenanceid;
   static claimNo;
   static movmentApproval =[]
   static loginArr=[]
   static loginUserDesg
   static maintenanceApproval =[]
   static imageBlobUrl
   static processId : number
   static currentProcessMonth;
   static bankRecieptNo =0;
   static payDemand;
   static expenseId=0;
}

export class ReportFilter
{
    companyId:any
    branchId
    documentType=""
    periodFrom=""
    periodTo=""
    fiscalYear=""
    costCenter
    processingStage
    file :FileReader;
    startDate : string;
    endDate : string;
    getAll : boolean;
    role
    data : any;
    allBranch : boolean;
    allCostCenter : boolean;
    userRole;
    invoiceIdArr;
}

export class ErrorCode {
    public static getErrorCode(data: any) {

        var code = data.status;


        switch (code) {
            case 0: {
                alert("Server Not Found")
            }
                break;
            case 100: {
                alert("Continue")
            }
                break;
            case 101: {
                alert("Switching Protocols")
            }
                break;
            case 103: {
                alert("Early Hints")
            }

            case 200:
                {
                    alert("Data Successfully Submitted")
                }
                break;
            case 201:
                {
                    alert("Data Successfully Created")
                }
                break;
            case 202:
                {
                    alert("Data  Accepted Successfully")
                }
                break;
            case 203: {
                alert("Non-A,uthoritative Information")
            }
                break;
            case 204: {
                alert("No Content")
            }
                break;
            case 205: {
                alert("Reset Content")
            }
                break;
            case 206: {
                alert("Partial Content")
            }
                break;
            case 300: {
                alert("Multiple Choices")
            }
                break;
            case 301: {
                alert("Moved Permanently")
            }
                break;
            case 302: {
                alert("Found")
            }
                break;
            case 303: {
                alert(" See Other")
            }
                break;
            case 304: {
                alert(" Not Modified")
            }
                break;
            case 307: {
                alert("Temporary Redirect")
            }
                break;
            case 308: {
                alert("Permanent Redirect")
            }
                break;

            case 400: {
                alert("Bad Request")
            }
                break;
            case 401: {
                alert("Unauthorized")
            }
                break;
            case 402: {
                alert("Payment Required")
            }
                break;
            case 403: {
                alert("Forbidden")
            }
                break;
            case 404: {
                alert("Not Found")
            }
                break;
            case 405: {
                alert("Method Not Allowed")
            }
                break;
            case 406: {
                alert("Not Acceptable")
            }
                break;
            case 407: {
                alert("Proxy Authentication Required")
            }
                break;
            case 408: {
                alert("Request Timeout")
            }
                break;
            case 409: {
                alert("Conflict")
            }
                break;
            case 410: {
                alert("Gone")
            }
                break;
            case 411: {
                alert("Length Required")
            }
                break;
            case 412: {
                alert("Precondition Failed")
            }
                break;
            case 413: {
                alert("Payload Too Large")
            }
                break;
            case 414: {
                alert("URI Too Long")
            }
                break;
            case 415: {
                alert("Unsupported Media Type")
            }
                break;
            case 416: {
                alert("Range Not Satisfiable")
            }
                break;
            case 417: {
                alert("Expectation Failed")
            }
                break;
            case 418: {
                alert("I m a teapot")
            }
                break;
            case 422: {
                alert("Unprocessable Entity")
            }
                break;
            case 425: {
                alert("Too Early")
            }
                break;
            case 426: {
                alert("Upgrade Required")
            }
                break;
            case 428: {
                alert("Precondition Required")
            }
                break;
            case 429: {
                alert("Too Many Requests")
            }
                break;
            case 431: {
                alert("Request Header Fields Too Large")
            }
                break;
            case 451: {
                alert(" Unavailable For Legal Reasons")
            }
                break;
            case 500:
                {
                    alert("Internal Server Error")
                }
                break;
            case 501:
                {
                    alert("Not Implemented")
                }
                break;
            case 502:
                {
                    alert("Bad Gateway")
                }
                break;
            case 503:
                {
                    alert("Service Unavailable")
                }
                break;
            case 504:
                {
                    alert("Gateway Timeout")
                }
                break;
            case 505:
                {
                    alert("HTTP Version Not Supported")
                }
                break;
            case 506:
                {
                    alert("Variant Also Negotiates")
                }
                break;
            case 507:
                {
                    alert("Insufficient Storage")
                }
                break;
            case 508:
                {
                    alert("Loop Detected")
                }
                break;
            case 510:
                {
                    alert("Not Extended")
                }
                break;
            case 511:
                {
                    alert("Network Authentication Required")
                }
                break;



        }



    }
}



import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  // template: `
  //   <span class="created-by">
  //   © 2020, Powered by A F Solutions
  //   </span>
  // `,
  template :'V 2.1',
})
export class FooterComponent {
}

<!-- <link rel="stylesheet" href="loader.css"> id="loader"-->
<div id="loader" *ngIf="loading">
    <!-- <img src="../../assets/images/processGif.gif" alt="" *ngIf="loading"> -->
</div>
<section class="login__container">
    <section class="row">
        <section class="login__form">
            <div class="login__heading">
                <!-- <img src="../../assets/images/logo.png" alt=""  class="logo"  />  -->
            </div>
            <div class="login__subheading mb-5">Society Champ</div>
            <div class="paraHeading mb-3" >
                Exclusively Designed for Shatabdi Enclave</div>
                <!-- <marquee class="marquee">Now Payment Service is available 24x7. Now you can in Pay Advance also</marquee> -->
     
                <div class="col-md-12 text-center">
                
                <!-- <div class="sub-title" style="font-family: Verdana, Geneva, Tahoma, sans-serif;text-shadow: 2px 2px 5px #2f2b2b;">
                    Exclusively designed for Shatabdi Enclave</div> -->
                <!-- <p class="sub-title">Please enter your Customer-ID & Password</p> -->
                <!-- <p class="sub-title" style="font-size: 12px;"> Timing 11.00 A.M. to 6.00 P.M.</p> -->
            </div>



            <form class="col-md-12" [formGroup]="loginForm" (ngSubmit)="userLogin();myFunction()">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="societyCode" value="2145" disabled placeholder="Society Code "
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.societyCode.errors }" > <!--(blur)="getCompany()"-->
                    <div *ngIf="loginCheck && f.societyCode.errors" class="invalid-feedback">
                        <div *ngIf="f.societyCode.errors.required">Society Code is required</div>
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" formControlName="user" placeholder="Customer ID"
                        aria-label="Email" aria-describedby="Email" autofocus
                        [ngClass]="{ 'is-invalid': loginCheck && f.user.errors }"> <!-- (blur)="getLoginId()"-->
                    <div *ngIf="loginCheck && f.user.errors" class="invalid-feedback">
                        <div *ngIf="f.user.errors.required">Customer ID is required</div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input type="password" class="form-control" formControlName="password" placeholder="Password"
                        aria-label="Password" aria-describedby="Password"
                        [ngClass]="{ 'is-invalid': loginCheck && f.password.errors }">
                    <div *ngIf="loginCheck && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>

                <div class="text-right mb-3">
                    <button type="submit" class="btn btn-primary cursor-pointer">Login</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" (click)="forgot()" class="btn btn-primary cursor-pointer">Forgot</button>
                </div>
                <div >
                </div>
            </form>
            <!-- div class="font-weight-light font-italic text-center mt-5 pt-4">Best suited for Microsoft Edge and
        Firefox 54.x. Also available for the browser of your smartphone / tablet.</div -->
        </section>
    </section>
</section>

